// eslint-disable-next-line max-classes-per-file
import React, { useContext, useEffect, useState } from 'react';
import ReactDataSheet from 'react-datasheet';
import dayjs from './dayjs';
import firebase from './firebase';
// Be sure to include styles at some point, probably during your bootstrapping
import 'react-datasheet/lib/react-datasheet.css';
import ShopsContext from './ShopsContext';

export interface GridElement extends ReactDataSheet.Cell<GridElement, number> {
  value: number | null;
}

class MyReactDataSheet extends ReactDataSheet<GridElement, number> {}

class StringUpdateHandler {
  shopId: string;

  column: string;

  constructor(shopId: string, column: string) {
    this.shopId = shopId;
    this.column = column;
  }

  update(value: string, batch: firebase.firestore.WriteBatch) {
    const ref = firebase.firestore().collection('shops').doc(this.shopId);

    const updateHash = {};
    updateHash[this.column] = value;
    batch.update(ref, updateHash);

    return true;
  }
}

class NumberUpdateHandler {
  shopId: string;

  column: string;

  constructor(shopId: string, column: string) {
    this.shopId = shopId;
    this.column = column;
  }

  update(value: string, batch: firebase.firestore.WriteBatch) {
    const ref = firebase.firestore().collection('shops').doc(this.shopId);

    const updateHash = {};

    const intValue = Number.parseInt(value, 10);

    if (Number.isNaN(intValue)) {
      updateHash[this.column] = null;
      batch.update(ref, updateHash);
    } else {
      updateHash[this.column] = intValue;
      batch.update(ref, updateHash);
    }
    return true;
  }
}

class TimestampUpdateHandler {
  shopId: string;

  column: string;

  constructor(shopId: string, column: string) {
    this.shopId = shopId;
    this.column = column;
  }

  update(value: string, batch: firebase.firestore.WriteBatch) {
    const ref = firebase.firestore().collection('shops').doc(this.shopId);

    const updateHash = {};

    const time = dayjs.tz(value, 'Asia/Tokyo');
    if (!time.isValid()) {
      return false;
    }

    updateHash[this.column] = time.toDate();
    batch.update(ref, updateHash);

    return true;
  }
}

function ShopInfoBatchUpdate() {
  const [data, setData] = useState<Array<any>>();
  const [parentShopId, setParentShopId] = useState<string>();

  const { shops } = useContext(ShopsContext);

  useEffect(() => {
    const unregisterShops = firebase
      .firestore()
      .collection('shops')
      .where('status', '==', 'active')
      .orderBy('order')
      .onSnapshot((snapshot) => {
        console.log('onSnapshot ShopInfoBatchUpdate');
        const grid: any = [];

        const header = [
          { value: '', width: 200, readOnly: true },
          { value: '注意事項（日本語）', width: 250, readOnly: true },
          { value: '注意事項（英語）', width: 250, readOnly: true },
          { value: 'お知らせ（日本語）', width: 250, readOnly: true },
          { value: 'お知らせ（英語）', width: 250, readOnly: true },
          { value: 'お知らせ表示期限 (書式 2022-04-30 23:59)', width: 250, readOnly: true },
          { value: '営業日（日本語）', width: 250, readOnly: true },
          { value: '営業時間（日本語）', width: 250, readOnly: true },
          { value: '営業日（英語）', width: 250, readOnly: true },
          { value: '営業時間（英語）', width: 250, readOnly: true },
          { value: '注文番号初期値', width: 50, readOnly: true },
          { value: '注文番号最大値', width: 50, readOnly: true },
          { value: '盛り付けクルーレベル', width: 50, readOnly: true },
          { value: 'チームボーナスゴール食数', width: 50, readOnly: true },
          { value: 'チームボーナスクーポン割引率', width: 50, readOnly: true },
          { value: 'チームボーナスクーポン割引額', width: 50, readOnly: true },
        ];

        grid.push(header);

        for (const shop of snapshot.docs) {
          if (parentShopId) {
            if (!(shop.id === parentShopId || shop.data().kitchen_shop_id === parentShopId)) {
              continue;
            }
          }

          const row = [
            {
              value: shop.data().short_name,
              width: 150,
              readOnly: true,
            },
            {
              value: shop.data().remark,
              width: 150,
              readOnly: false,
              handler: new StringUpdateHandler(shop.id, 'remark'),
            },
            {
              value: shop.data().remark_en,
              width: 150,
              readOnly: false,
              handler: new StringUpdateHandler(shop.id, 'remark_en'),
            },
            {
              value: shop.data().flash_message,
              width: 150,
              readOnly: false,
              handler: new StringUpdateHandler(shop.id, 'flash_message'),
            },
            {
              value: shop.data().flash_message_en,
              width: 150,
              readOnly: false,
              handler: new StringUpdateHandler(shop.id, 'flash_message_en'),
            },
            {
              value: shop.data().flash_message_until
                ? dayjs(shop.data().flash_message_until.toDate()).tz('Asia/Tokyo').format('YYYY-MM-DD HH:mm')
                : '',
              width: 150,
              readOnly: false,
              handler: new TimestampUpdateHandler(shop.id, 'flash_message_until'),
            },
            {
              value: shop.data().opening_days,
              width: 150,
              readOnly: false,
              handler: new StringUpdateHandler(shop.id, 'opening_days'),
            },
            {
              value: shop.data().opening_hours,
              width: 150,
              readOnly: false,
              handler: new StringUpdateHandler(shop.id, 'opening_hours'),
            },
            {
              value: shop.data().opening_days_en,
              width: 150,
              readOnly: false,
              handler: new StringUpdateHandler(shop.id, 'opening_days_en'),
            },
            {
              value: shop.data().opening_hours_en,
              width: 150,
              readOnly: false,
              handler: new StringUpdateHandler(shop.id, 'opening_hours_en'),
            },
            {
              value: shop.data().initial_order_sequence,
              width: 150,
              readOnly: false,
              handler: new NumberUpdateHandler(shop.id, 'initial_order_sequence'),
            },
            {
              value: shop.data().max_order_sequence,
              width: 150,
              readOnly: false,
              handler: new NumberUpdateHandler(shop.id, 'max_order_sequence'),
            },
            {
              value: shop.data().cooking_crew_level,
              width: 150,
              readOnly: false,
              handler: new NumberUpdateHandler(shop.id, 'cooking_crew_level'),
            },
            {
              value: shop.data().team_bonus_goal,
              width: 150,
              readOnly: false,
              handler: new NumberUpdateHandler(shop.id, 'team_bonus_goal'),
            },

            {
              value: shop.data().team_bonus_coupon_discount_percentage,
              width: 150,
              readOnly: false,
              handler: new NumberUpdateHandler(shop.id, 'team_bonus_coupon_discount_percentage'),
            },

            {
              value: shop.data().team_bonus_coupon_discount_amount,
              width: 150,
              readOnly: false,
              handler: new NumberUpdateHandler(shop.id, 'team_bonus_coupon_discount_amount'),
            },
          ];

          grid.push(row);
        }
        setData(grid);
      });

    return () => {
      unregisterShops();
    };
  }, [parentShopId]);

  const onGridRowsUpdated = (changes) => {
    const batch = firebase.firestore().batch();

    setData((prevData) => {
      const grid = prevData!.map((row) => [...row]);
      changes.forEach(({ cell, row, col, value }) => {
        let updatingValue: null | number = null;

        updatingValue = value;

        grid[row][col] = { ...grid[row][col], updatingValue };

        if (cell.handler) {
          cell.handler.update(updatingValue, batch);
        }
      });

      batch.commit();
      return grid;
    });
  };

  const change = (e) => {
    setParentShopId(e.target.value as string);
  };

  return (
    <>
      <select id="shops" className="form-control form-control-sm" onChange={change} style={{ width: '400px' }}>
        <option value="">親店舗選択</option>
        {shops === undefined
          ? ''
          : Object.keys(shops)
              .filter((shopId: string) => !shops[shopId].data()!.kitchen_shop_id && shops[shopId].data()!.enabled)
              .map((shopId: string) => (
                <option key={shopId} value={shopId}>
                  {shops[shopId].data()!.short_name}
                </option>
              ))}
      </select>

      {data && (
        <MyReactDataSheet
          className="reset-bootstrap-for-datasheet"
          data={data}
          valueRenderer={(cell) => cell.value}
          onCellsChanged={onGridRowsUpdated}
        />
      )}
    </>
  );
}

export default ShopInfoBatchUpdate;
