import React, { useContext, useEffect, useState } from 'react';
import { StringParam, useQueryParam, withDefault } from 'use-query-params';
import dayjs from './dayjs';
import firebase from './firebase';
import ShopsContext from './ShopsContext';
import TimeLeft from './TimeLeft';

function AllDeliveries() {
  const [date, setDate] = useQueryParam('date', StringParam);
  const [deliveries, setDeliveries] = useState<Array<any>>();
  const { shops } = useContext(ShopsContext);
  const [orderBy, setOrderBy] = useQueryParam('order', withDefault(StringParam, 'time'));

  useEffect(() => {
    const dayjsDate = date ? dayjs.tz(date, 'Asia/Tokyo') : dayjs().tz('Asia/Tokyo');
    const startOfDay = dayjsDate.startOf('day').toDate();
    const endOfDay = dayjsDate.endOf('day').toDate();

    const unregisterShopsObserver = firebase
      .firestore()
      .collectionGroup('slots')
      .where('open', 'in', [true, false]) // indexを使うため
      .where('time', '>=', startOfDay)
      .where('time', '<', endOfDay)
      .orderBy('time')
      .onSnapshot((snap) => {
        console.log('onSnapshot AllDeliverries');
        const records: Array<any> = [];

        snap.forEach((docSnapshot) => {
          if (docSnapshot.data().delivery_group) {
            records.push(docSnapshot);
          }
        });

        // time_untilでソート。index数を節約のため一旦time_untilをindexに入れないでクライアント側で再ソート
        if (orderBy === 'time') {
          records.sort((a, b) => {
            return a.data().time_until.toDate().getTime() - b.data().time_until.toDate().getTime();
          });
        } else if (orderBy === 'shop') {
          records.sort((a, b) => {
            const shopOrderA = shops[a.data().kitchen_shop_id].data()?.order ?? 0;
            const shopOrderB = shops[b.data().kitchen_shop_id].data()?.order ?? 0;

            if (shopOrderA !== shopOrderB) {
              return shopOrderA - shopOrderB;
            }
            return a.data().time_until.toDate().getTime() - b.data().time_until.toDate().getTime();
          });
        }

        setDeliveries(records);
      });

    return () => {
      unregisterShopsObserver();
    };
  }, [date, shops, orderBy]);

  const dateChanged = (e) => {
    setDate(e.target.value);
  };

  const renderSlot = (slot: firebase.firestore.DocumentSnapshot) => {
    const data = slot.data()!;

    const kitchenShop = shops[data.kitchen_shop_id];
    const shop = shops[data.shop_id];
    const cols: Array<JSX.Element> = [];

    cols.push(<td>{kitchenShop.data()!.short_name}</td>);
    cols.push(<td>{data.delivery_group.name}</td>);

    if (kitchenShop.id !== shop.id) {
      cols.push(<td>{shop.data()!.short_name}</td>);
    }

    cols.push(<td className="text-right">{data.ordered}</td>);

    const status =
      data.ordered === 0
        ? 'no_delivery'
        : !data.batch_delivering_at
        ? 'waiting'
        : data.batch_delivered_at
        ? 'delivered'
        : 'delivering';

    /*     cols.push(
      <td className="text-right">
        {dayjs(data.batch_delivery_departure_due_time.toDate()).tz('Asia/Tokyo').format('HH:mm')}
      </td>,
    );

    if (data.batch_delivering_at) {
      cols.push(
        <td className="text-right">{dayjs(data.batch_delivering_at.toDate()).tz('Asia/Tokyo').format('HH:mm')}</td>,
      );
    } else if (status !== 'no_delivery') {
      cols.push(
        <td className="text-right">
          <small>
            <TimeLeft deadline={data.batch_delivery_departure_due_time.toDate()} />
          </small>
        </td>,
      );
    } else {
      cols.push(<td className="text-right">-</td>);
    } */

    cols.push(<td className="text-right">{dayjs(data.time_until.toDate()).tz('Asia/Tokyo').format('HH:mm')}</td>);
    if (status === 'delivered') {
      cols.push(
        <td className="text-right">{dayjs(data.batch_delivered_at.toDate()).tz('Asia/Tokyo').format('HH:mm')}</td>,
      );
    } else if (status !== 'no_delivery') {
      cols.push(
        <td className="text-right">
          <small>
            <TimeLeft deadline={data.time_until.toDate()} />
          </small>
        </td>,
      );
    } else {
      cols.push(<td className="text-right">-</td>);
    }

    let statusNode: JSX.Element;
    switch (status) {
      case 'no_delivery':
        statusNode = (
          <span className="badge badge-dark" role="alert">
            配達なし
          </span>
        );
        break;
      case 'waiting':
        statusNode = (
          <span className="badge badge-secondary" role="alert">
            配達準備中
          </span>
        );
        break;
      case 'delivering':
        statusNode = (
          <span className="badge badge-secondary" role="alert">
            配達中
          </span>
        );
        break;
      case 'delivered':
        statusNode = (
          <span className="badge badge-primary" role="alert">
            配達完了
          </span>
        );
        break;
      default:
        statusNode = (
          <span className="badge badge-danger" role="alert">
            エラー(その他)
          </span>
        );
    }

    const additonalStatus: JSX.Element[] = [];

    if (data.batch_delivery_boss_reported_at) {
      additonalStatus.push(<small>遅報済</small>);
    }

    if (data.batch_delivery_customer_reported_at) {
      additonalStatus.push(<small>客連済</small>);
    }

    cols.push(
      <td>
        {statusNode}{' '}
        {additonalStatus.map((s) => (
          <span className="px-1">{s}</span>
        ))}
      </td>,
    );

    return cols;
  };

  return (
    <div className="container">
      <input type="date" onChange={dateChanged} defaultValue={date ?? ''} />

      {deliveries === undefined ? (
        <>loading</>
      ) : (
        <>
          <div className="container-fluid h-100">
            <table className="table table-sm">
              <thead>
                <tr>
                  <th>
                    <button type="button" className="btn p-0 font-weight-bold" onClick={() => setOrderBy('shop')}>
                      店舗{' '}
                      <i className={`fas fa-sort-up fa-lg ${orderBy === 'shop' ? 'text-primary' : 'text-muted'} `} />
                    </button>
                  </th>
                  <th>デリバリーグループ</th>
                  <th>法人</th>
                  <th>食数</th>
                  {/*                   <th>出発予定</th>
                  <th>出発時間</th>
 */}
                  <th>
                    <button type="button" className="btn p-0 font-weight-bold" onClick={() => setOrderBy('time')}>
                      <span>
                        配達予定{' '}
                        <i className={`fas fa-sort-up fa-lg ${orderBy === 'time' ? 'text-primary' : 'text-muted'} `} />
                      </span>
                    </button>
                  </th>
                  <th>完了時間</th>
                  <th>状態</th>
                </tr>
              </thead>
              <tbody>
                {deliveries.map((slot) => (
                  <tr key={`${slot.data().shop_id}-${slot.id}`}>{renderSlot(slot)}</tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      )}
    </div>
  );
}

export default AllDeliveries;
